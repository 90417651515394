import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import isObjectLike from 'lodash/isObjectLike';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './Flag.pcss';


class Flag extends React.PureComponent {

    static propTypes = {
      type        : PropTypes.oneOf(['success', 'error']),
      labelMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.element,
      ]),
    };


    renderLabel() {
      const { labelMessage } = this.props;
      if (!labelMessage) {
        return null;
      }
      if (isObjectLike(labelMessage) && !React.isValidElement(labelMessage)) {
        return <FormattedMessage {...labelMessage} />;
      }
      return labelMessage;
    }


    renderImage() {
      return (
        <div
          className={cn(styles.flag__image,
            {
              [styles[`flag__image--${this.props.type}`]]: this.props.type,
            },)}
        />
      );
    }


    render() {
      return (
        <div
          className={cn(styles.flag)}
        >
          {this.renderImage()}
          {this.renderLabel() }
        </div>
      );
    }

}

export default withStyles(styles)(Flag);
