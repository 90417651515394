import { defineMessages } from 'react-intl';


export default defineMessages({
  headers: {
    countries                 : { id: 'country.headers.countries' },
    countryConfiguration      : { id: 'country.headers.countryConfiguration' },
    MFADisableWarning         : { id: 'country.headers.MFADisableWarning' },
    MFAEnableSMSWarning       : { id: 'country.headers.MFAEnableSMSWarning' },
    addNewCountry             : { id: 'country.headers.addNewCountry' },
    deleteCountry             : { id: 'country.headers.deleteCountry' },
    unableToDeleteCountry     : { id: 'country.headers.unableToDeleteCountry' },
    enableCountryFailed       : { id: 'country.headers.enableCountryFailed' },
    unsavedConfiguration      : { id: 'country.headers.unsavedConfiguration' },
    generalOptions            : { id: 'country.headers.generalOptions' },
    MFA                       : { id: 'country.headers.MFA' },
    isLicenceMandatory        : { id: 'country.headers.isLicenceMandatory' },
    isPrivateCloud            : { id: 'country.headers.isPrivateCloud' },
    language                  : { id: 'country.headers.language' },
    cdaStoreLink              : { id: 'country.headers.cdaStoreLink' },
    availableScopes           : { id: 'country.headers.availableScopes' },
    selectUnit                : { id: 'country.headers.selectUnit' },
    addNewPayer               : { id: 'country.headers.addNewPayer' },
    deletePayer               : { id: 'country.headers.deletePayer' },
    metersAssignedToCountry   : { id: 'country.headers.metersAssignedToCountry' },
    metersUnassignedToCountry : { id: 'country.headers.metersUnassignedToCountry' },
    accountIdentifiers        : { id: 'country.headers.accountIdentifiers' },
    clinicPatient             : { id: 'country.headers.clinicPatient' },
    organizationIdentifiers   : { id: 'country.headers.organizationIdentifiers' },
    nationalIdentifiersRecords: { id: 'country.headers.nationalIdentifiersRecords' },
    addNewIdentifierOption    : { id: 'country.headers.addNewIdentifierOption' },
    updateIdentifierOption    : { id: 'country.headers.updateIdentifierOption' },
    glucoseType               : {
      default    : { id: 'country.headers.glucoseType.default' },
      type1      : { id: 'country.headers.glucoseType.type1' },
      type2      : { id: 'country.headers.glucoseType.type2' },
      prediabetes: { id: 'country.headers.glucoseType.prediabetes' },
      gestational: { id: 'country.headers.glucoseType.gestational' },
    },
  },
  labels: {
    countryName           : { id: 'country.labels.countryName' },
    countryCode           : { id: 'country.labels.countryCode' },
    defaultLanguage       : { id: 'country.labels.defaultLanguage' },
    isEnableFlag          : { id: 'country.labels.isEnableFlag' },
    enable                : { id: 'country.labels.enable' },
    disable               : { id: 'country.labels.disable' },
    language              : { id: 'country.labels.language' },
    linkForIOS            : { id: 'country.labels.linkForIOS' },
    linkForAndroid        : { id: 'country.labels.linkForAndroid' },
    defaultCountryLanguage: { id: 'country.labels.defaultCountryLanguage' },
    termsOfServices       : { id: 'country.labels.termsOfServices' },
    sms                   : { id: 'country.labels.sms' },
    email                 : { id: 'country.labels.email' },
    callingCode           : { id: 'country.labels.callingCode' },
    firstDayOfWeek        : { id: 'country.labels.firstDayOfWeek' },
    professional          : { id: 'country.labels.professional' },
    clinicStaff           : { id: 'country.labels.clinicStaff' },
    personal              : { id: 'country.labels.personal' },
    caregiver             : { id: 'country.labels.caregiver' },
    metricName            : { id: 'country.labels.metricName' },
    metricUnit            : { id: 'country.labels.metricUnit' },
    glucosePostMealHigh   : { id: 'country.labels.glucosePostMealHigh' },
    glucosePostMealLow    : { id: 'country.labels.glucosePostMealLow' },
    glucosePreMealHigh    : { id: 'country.labels.glucosePreMealHigh' },
    glucosePreMealLow     : { id: 'country.labels.glucosePreMealLow' },
    glucoseCriticalHigh   : { id: 'country.labels.glucoseCriticalHigh' },
    glucoseCriticalLow    : { id: 'country.labels.glucoseCriticalLow' },
    payerName             : { id: 'country.labels.payerName' },
    action                : { id: 'country.labels.action' },
    meterName             : { id: 'country.labels.meterName' },
    metersSku             : { id: 'country.labels.metersSku' },
    phone                 : { id: 'country.labels.phone' },
    otherInformation      : { id: 'country.labels.otherInformation' },
    personalIdentifier    : { id: 'country.labels.personalIdentifier' },
    licence               : { id: 'country.labels.licence' },
    designation           : { id: 'country.labels.designation' },
    zipCode               : { id: 'country.labels.zipCode' },
    state                 : { id: 'country.labels.state' },
    organizationIdentifier: { id: 'country.labels.organizationIdentifier' },
    countryEnabled        : { id: 'country.labels.countryEnabled' },
    countryDisabled       : { id: 'country.labels.countryDisabled' },

  },
  buttons: {
    config             : { id: 'country.buttons.config' },
    addCountry         : { id: 'country.buttons.addCountry' },
    cancel             : { id: 'country.buttons.cancel' },
    save               : { id: 'country.buttons.save' },
    backToListCountries: { id: 'country.buttons.backToListCountries' },
    enableCountry      : { id: 'country.buttons.enableCountry' },
    addNewPayer        : { id: 'country.buttons.addNewPayer' },
    addPayer           : { id: 'country.buttons.addPayer' },
    addNew             : { id: 'country.buttons.addNew' },
    update             : { id: 'country.buttons.update' },
  },
  placeholders: {
    countryName     : { id: 'country.placeholders.countryName' },
    countryCode     : { id: 'country.placeholders.countryCode' },
    language        : { id: 'country.placeholders.language' },
    searchCountry   : { id: 'country.placeholders.searchCountry' },
    linkForIOS      : { id: 'country.placeholders.linkForIOS' },
    linkForAndroid  : { id: 'country.placeholders.linkForAndroid' },
    firstDayOfWeek  : { id: 'country.placeholders.firstDayOfWeek' },
    termsOfServices : { id: 'country.placeholders.termsOfServices' },
    callingCode     : { id: 'country.placeholders.callingCode' },
    searchPayer     : { id: 'country.placeholders.searchPayer' },
    addPayer        : { id: 'country.placeholders.addPayer' },
    searchMeters    : { id: 'country.placeholders.searchMeters' },
    searchIdentifier: { id: 'country.placeholders.searchIdentifier' },
  },
  infos: {
    addNewCountry                  : { id: 'country.infos.addNewCountry' },
    MFADisableEmailWarning         : { id: 'country.infos.MFADisableEmailWarning' },
    MFADisableSMSWarning           : { id: 'country.infos.MFADisableSMSWarning' },
    MFAEnableSMSWarning            : { id: 'country.infos.MFAEnableSMSWarning' },
    deleteCountry                  : { id: 'country.infos.deleteCountry' },
    unableToDeleteCountry          : { id: 'country.infos.unableToDeleteCountry' },
    enableCountrySuccess           : { id: 'country.infos.enableCountrySuccess' },
    enableCountryFailed            : { id: 'country.infos.enableCountryFailed' },
    confirmEnableCountry           : { id: 'country.infos.confirmEnableCountry' },
    unsavedConfigurationOnChangeTab: { id: 'country.infos.unsavedConfigurationOnChangeTab' },
    unsavedConfigurationOnClickBack: { id: 'country.infos.unsavedConfigurationOnClickBack' },
    saveOnActiveCountry            : { id: 'country.infos.saveOnActiveCountry' },
    addNewPayer                    : { id: 'country.infos.addNewPayer' },
    deletePayer                    : { id: 'country.infos.deletePayer' },
    noneMetersAssignedToCountry    : { id: 'country.infos.noneMetersAssignedToCountry' },
    emptyCountryWillAssignAll      : { id: 'country.infos.emptyCountryWillAssignAll' },
    nationalIdentifiersRecords     : { id: 'country.infos.nationalIdentifiersRecords' },
    addNewIdentifierOption         : { id: 'country.infos.addNewIdentifierOption' },
    updateIdentifierOption         : { id: 'country.infos.updateIdentifierOption' },
    moleUnitSelected               : { id: 'country.infos.moleUnitSelected' },
  },
  tabs: {
    configureCountry: {
      general            : { id: 'country.tabs.configureCountry.general' },
      unitsOfMeasurements: { id: 'country.tabs.configureCountry.unitsOfMeasurements' },
      glucoseRanges      : { id: 'country.tabs.configureCountry.glucoseRanges' },
      payers             : { id: 'country.tabs.configureCountry.payers' },
      identifiers        : { id: 'country.tabs.configureCountry.identifiers' },
      meters             : { id: 'country.tabs.configureCountry.meters' },
    },
  },
  unitsOfMeasurements: {
    names: {
      bloodGlucoseConcentration: { id: 'country.unitsOfMeasurements.names.bloodGlucoseConcentration' },
      weight                   : { id: 'country.unitsOfMeasurements.names.weight' },
      height                   : { id: 'country.unitsOfMeasurements.names.height' },
      calcium                  : { id: 'country.unitsOfMeasurements.names.calcium' },
      cholesterol              : { id: 'country.unitsOfMeasurements.names.cholesterol' },
      cockcroftGaultFiltration : { id: 'country.unitsOfMeasurements.names.cockcroftGaultFiltration' },
      creatinine               : { id: 'country.unitsOfMeasurements.names.creatinine' },
      filtration               : { id: 'country.unitsOfMeasurements.names.filtration' },
      hbA1C                    : { id: 'country.unitsOfMeasurements.names.hbA1C' },
      phosphorus               : { id: 'country.unitsOfMeasurements.names.phosphorus' },
      potassium                : { id: 'country.unitsOfMeasurements.names.potassium' },
      pressure                 : { id: 'country.unitsOfMeasurements.names.pressure' },
      sodium                   : { id: 'country.unitsOfMeasurements.names.sodium' },
      triglycerides            : { id: 'country.unitsOfMeasurements.names.triglycerides' },
    },
  },
  identifierTab: {
    headers: {
      accountIdentifiers: {
        personalIdentifier: { id: 'country.identifierTab.headers.accountIdentifiers.personalIdentifier' },
        licence           : { id: 'country.identifierTab.headers.accountIdentifiers.licence' },
      },
      organizationIdentifiers: {
        organizationIdentifier: { id: 'country.identifierTab.headers.organizationIdentifiers.organizationIdentifier' },
      },
    },
    labels: {
      name                  : { id: 'country.identifierTab.labels.name' },
      termKey               : { id: 'country.identifierTab.labels.termKey' },
      validationRule        : { id: 'country.identifierTab.labels.validationRule' },
      instructionTerm       : { id: 'country.identifierTab.labels.instructionTerm' },
      regExp                : { id: 'country.identifierTab.labels.regExp' },
      personalIdentifier    : { id: 'country.identifierTab.labels.personalIdentifier' },
      licence               : { id: 'country.identifierTab.labels.licence' },
      organizationIdentifier: { id: 'country.identifierTab.labels.organizationIdentifier' },
    },
  },
  errors: {
    enableCountry: {
      firstDayOfWeek               : { id: 'country.errors.enableCountry.firstDayOfWeek' },
      termsOfServices              : { id: 'country.errors.enableCountry.termsOfServices' },
      availableScopes              : { id: 'country.errors.enableCountry.availableScopes' },
      defaultGlucoseRanges         : { id: 'country.errors.enableCountry.defaultGlucoseRanges' },
      countryMustBeAssignedToRegion: { id: 'country.errors.enableCountry.countryMustBeAssignedToRegion' },
    },
    businessErrors: {
      CountryExist                   : { id: 'country.errors.businessErrors.CountryExist' },
      ActiveIdentifierRequiresOptions: { id: 'country.errors.businessErrors.ActiveIdentifierRequiresOptions' },
    },
  },
});

